import ng from 'angular';
import { acpDebitCardTransfersComponentModule } from 'apps/debit-card-transfers/components/debit-card-transfers';
import acpComponentPaywalls from 'components/paywalls';
import acpCore from 'core';
import {
  acpDebitCardTransfersSection,
  AcpDebitCardTransfersSectionComponentCtrl
} from './acp-debit-card-transfers-section-component';
import acpDebitCardTransfersContent from './debit-card-transfers-content.yml';
import './styles/${theme}/core.scss';

export const acpDebitCardTransferModule = ng
  .module('acp.section.debit-card-transfers', [
    acpCore.name,
    acpComponentPaywalls.name,
    acpDebitCardTransfersComponentModule.name
  ])
  .component('acpDebitCardTransfersSection', acpDebitCardTransfersSection)
  .run((contentSectionCache) => {
    'ngInject';

    contentSectionCache.put(
      'apps/debit-card-transfers',
      acpDebitCardTransfersContent
    );
  });

export default acpDebitCardTransferModule;
export { AcpDebitCardTransfersSectionComponentCtrl };
