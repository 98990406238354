import ng from 'angular';
import acpDebitCardTransfersTemplate from './templates/acp-debit-card-transfers.html';

export class AcpDebitCardTransfersSectionComponentCtrl
  implements nsUtils.NsComponentController {
  public paywallsPassed: boolean = false;
  public contactCS: boolean = false;
  constructor(nsComponentDecorator, private acpCoreDispatcher) {
    'ngInject';

    nsComponentDecorator(this, this);
  }

  public $onInit(): void {
    // Perform initialization here
    this.$onValue(this.acpCoreDispatcher.paywalls.passed, () => {
      this.contactCS = false;
      this.paywallsPassed = true;
    });
    this.$onValue(this.acpCoreDispatcher.paywalls.failed, (data: any) => {
      this.contactCS = true;
    });
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
  public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);
}

export const acpDebitCardTransfersSection: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpDebitCardTransfersSectionComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpDebitCardTransfersTemplate
};
